import React, { useEffect, useRef, useState } from 'react';
import classes from './ExcursionReviews.module.scss';
import { dateParse } from "../../../helpers/DateHelper";
import HbButton from "../../UI/HbButton/HbButton";
import RatingStarsRow from "../../dashboard/common/RatingStarsRow/RatingStarsRow";
import RatingStarFilled from "../../../images/icons/RatingStarFilled.svg";
import { ImgStretchingFixedDiv } from 'helpers/ImgStretching';
import { baseURL } from 'API/HttpAxios';
import { useSearchParams } from 'react-router-dom';
import { useObserverBlockEntry } from 'hooks/useObserverBlockEntry';


const ExcursionReviews = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Get photo count for different screen sizes
    if (window.innerWidth < 950) {
        var last_index = 2;
    } else {
        var last_index = 4;
    }

    // Click on review img
    const clickOnReviewImg = (e) => {
        e.stopPropagation();
        setSearchParams({ ...searchParams, 'reviews_img_src': e.currentTarget.getAttribute('data-img-src') });
    }

    // Show more reviews
    const showMoreReviews = (e) => {
        props.reviewsVisible(true);
    }

    // Show review photo by scroll
    const reviewContainer = useRef(null);
    const [isReviewPhotosVisible, setIsReviewPhotosVisible] = useState(false);
    const isReviewPhotosEnter = useObserverBlockEntry(reviewContainer);

    useEffect(() => {
        if (isReviewPhotosEnter) {
            setIsReviewPhotosVisible(true);
        }
    }, [isReviewPhotosEnter])

    return (
        <div
            className={classes.reviews_container}
            ref={reviewContainer}
        >
            <div className={classes.reviews_title_row}>
                <h2 className={classes.reviews_title}>
                    <span><img className={classes.reviews_title_star} src={RatingStarFilled} /></span>
                    <span>{props.excursion.rating}</span>
                    <span>(отзывов: {props.excursion.reviews_count})</span>

                    {/* <img src={RatingStarFilled}/>
                    {props.excursion.rating}
                    (отзывов {props.excursion.reviews_count}) */}
                    {/* Что о нас думают гости: */}
                </h2>
            </div>

            {/*<div className={classes.reviews_count_row}>*/}
            {/*    <div className={classes.pink_dot}>*/}

            {/*    </div>*/}

            {/*    <div className={classes.reviews_count}>*/}
            {/*        отзывов ({priceFormatting(props.excursion.reviews_count)})*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div
                className={classes.reviews_row}
            >
                {
                    props.excursion.reviews.map((review, index) => {
                        return (
                            <div
                                className={`roundblock_angle_rb ${classes.review_container}`}
                                key={`review_${index}`}
                                onClick={showMoreReviews}
                            >
                                <div className={`${classes.review_block}`}>
                                    <div className={classes.profile_row}>
                                        <div className={classes.user_avatar}>

                                        </div>

                                        <div className={classes.user_data}>
                                            <div className={classes.name_rating_container}>
                                                <div className={[classes.user_name, 'truncate'].join(' ')}>
                                                    {review.is_anonymously ? 'Скрытный авантюрист' : review.user_name}
                                                </div>

                                                <div className={classes.user_rating_container}>
                                                    <div
                                                        className={classes.rating_star_row}
                                                    >
                                                        {
                                                            [...Array(+review.rating)].map((_, index) => {
                                                                return (
                                                                    <img
                                                                        key={index}
                                                                        className={classes.rating_star_img}
                                                                        src={RatingStarFilled}
                                                                        alt={'star'}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <div
                                                        className={classes.rating_star_row_md}
                                                    >
                                                        <img
                                                            className={classes.rating_star_img}
                                                            src={RatingStarFilled}
                                                            alt={'star'}
                                                        />
                                                    </div>


                                                    {/* <div className={classes.user_rating}>
                                                        ({review.rating.toFixed(1)})
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className={classes.review_date}>
                                                {dateParse(review.created_at)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.review_body}>
                                        {review.body}
                                    </div>


                                    {/* Render photos */}
                                    <div className={classes.review_photos_block}>
                                        {
                                            review.review_imgs.map((photo, index) => {
                                                if (index > last_index) return null;

                                                return (
                                                    <div
                                                        key={`review_photo_${index}`}
                                                        className={classes.review_photo}
                                                        data-img-src={photo.img_src.match(/\/([\w\d]+)\.webp$/)[1]}
                                                        onClick={clickOnReviewImg}
                                                    >
                                                        {
                                                            isReviewPhotosVisible
                                                                ?
                                                                <img
                                                                    src={baseURL + photo.preview_img_src}
                                                                    alt={'review_photo'}
                                                                    onLoad={ImgStretchingFixedDiv}
                                                                    data-block={'review_photo'}
                                                                />
                                                                : null
                                                        }


                                                        {
                                                            index === last_index && review.review_imgs.length > last_index + 1
                                                                ?
                                                                (
                                                                    <div
                                                                        className={classes.review_photos_count}
                                                                    >
                                                                        +{review.review_imgs.length - last_index - 1}
                                                                    </div>
                                                                )
                                                                : ''
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            <div className={classes.reviews_button_row}>
                <HbButton
                    color={'black'}
                    className={classes.reviews_button}
                    onClick={() => props.reviewsVisible(true)}
                >
                    {/* Показать все отзывы - {props.excursion.reviews_count} */}
                    {/* Показать все отзывы: {props.excursion.reviews_count} */}
                    Показать все отзывы ({props.excursion.reviews_count})
                </HbButton>
            </div>
        </div>
    );
};

export default ExcursionReviews;