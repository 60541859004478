import React, { useContext, useEffect, useState } from 'react';
import classes from "./ExcursionBookingPreview.module.scss";
import HbButton from "../../UI/HbButton/HbButton";
import { isFullObj } from "../../../helpers/ObjHelper";
import TypeSelector from "../TypeSelector/TypeSelector";
import { priceFormatting } from "../../../helpers/PriceHelper";
import Clock from '../../../images/icons/Clock.svg';
import { useNavigate, useSearchParams } from "react-router-dom";
import HbLikeIcon from "../../UI/HbLikeIcon/HbLikeIcon";
// import LikeFill from "../../../images/icons/LikeFill.svg";
// import Like from "../../../images/icons/Like.svg";
import AuthContext from "../../../context/AuthContext";
import RatingStarFilled from "../../../images/icons/RatingStarFilled.svg";
import { useYandexSplit } from 'hooks/useYandexSplit';

const ExcursionBookingPreview = (props) => {
    const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Find adult price & discount
    const [discountPrice, setDiscountPrice] = useState(null);
    const [price, setPrice] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);

    useEffect(() => {
        console.log(props.excursionType);

        let initialPrice = props.excursionType.current_prices[0].price;
        setPrice(initialPrice);
        setDiscountPrice(0);

        if (props.excursionType.discount_prices.length > 0) {
            let adultsDiscount = props.excursionType.discount_prices[0];

            if (adultsDiscount !== undefined) {
                setDiscountPrice(adultsDiscount.price);
                setDiscountPercent(adultsDiscount.value);
            }
        }
    }, [props.excursionType])

    // Save/delete excursion to favorite
    const [likeClick, setLikeClick] = useState(false);
    const handleLikeClick = (event) => {
        event.stopPropagation();
        setLikeClick(true);
    }

    // Change button wish text
    const [wishText, setWishText] = useState('');
    useEffect(() => {
        if (isAuth && user.hasOwnProperty('wishlist')) {
            const findWish = user.wishlist.find(el => el.excursion_id === props.excursion.id);
            if (findWish !== undefined) {
                setWishText('В избранном');
            } else {
                setWishText('В избранное');
            }
        } else {
            setWishText('В избранное');
        }
    }, [user, isAuth])

    // Set yandex split widget
    const [YaPay, paymentData, setPaymentData] = useYandexSplit({});

    useEffect(() => {
        // console.log('paymentData');
        // console.log(paymentData);
        // console.log('discountPrice');
        // console.log(discountPrice);
        // console.log('price');
        // console.log(price);


        if (YaPay !== null) {
            console.log('Setting paymentData.totalAmount');

            let initialPrice = props.excursionType.current_prices[0].price;
            let adultsDiscount = 0;

            if (props.excursionType.discount_prices.length > 0) {
                adultsDiscount = props.excursionType.discount_prices[0].price;
            }

            // YaPay.paymentData.totalAmount =  discountPrice === null ? price.toFixed(2) : discountPrice.toFixed(2);
            setPaymentData({
                ...paymentData,
                totalAmount: adultsDiscount === 0 ? initialPrice.toFixed(2) : adultsDiscount.toFixed(2)
            });
        }
    }, [YaPay, props.excursionType])

    // Обработчик на клик по кнопке
    // Функция должна возвращать промис которые резолвит ссылку на оплату полученную от бэкенда Яндекс Пэй
    // Подробнее про создание заказа: https://pay.yandex.ru/ru/docs/custom/backend/yandex-pay-api/order/merchant_v1_orders-post
    async function onPayButtonClick() {
        // Создание заказа...
        // и возврат URL на оплату вида 'https://pay.ya.ru/l/XXXXXX'
    }

    // Обработчик на ошибки при открытии формы оплаты
    function onFormOpenError(reason) {
        // Выводим информацию о недоступности оплаты в данный момент
        // и предлагаем пользователю другой способ оплаты.
        console.error(`Payment error — ${reason}`);
    }

    const [activePaymentSession, setActivePaymentSession] = useState(null);

    useEffect(() => {
        if (activePaymentSession !== null) {
            activePaymentSession.destroy();
        }

        if (paymentData !== null && paymentData.totalAmount !== null) {
            console.log('Creating session...');

            YaPay.createSession(paymentData, {
                onPayButtonClick: onPayButtonClick,
                onFormOpenError: onFormOpenError,
            })
                .then((paymentSession) => {
                    setActivePaymentSession(paymentSession);

                    // paymentSession.mountWidget(
                    //     document.querySelector('#yandex_split_widget'),
                    //     { widgetType: YaPay.WidgetType.Simple }
                    // )
                })
        }
    }, [paymentData])

    return (
        <div className={classes.booking_container}>
            <div className={[classes.booking_block, "stroke_1_000_05"].join(" ")}>
                <div className={classes.booking_inner_container}>
                    <div className={classes.price_row}>
                        <div className={classes.price_block}>
                            {
                                discountPercent > 0
                                &&
                                <div className={classes.badges_container}>
                                    <span className={classes.discount_badge}>
                                        -{discountPercent}%
                                    </span>
                                </div>
                            }

                            <div className={classes.price_text}>
                                {
                                    isFullObj(props.excursionType)
                                        ?
                                        discountPrice !== null && discountPrice !== 0 && discountPrice !== price
                                            // discountPrice !== null && discountPrice !== 0
                                            ?
                                            <div className={classes.price_block_discount}>
                                                <div className={[classes.old_price, 'strike-center'].join(' ')}>
                                                    {priceFormatting(price)}
                                                    {/*<span className={"verdana_regular"}>₽</span>*/}
                                                </div>

                                                <div className={classes.new_price}>
                                                    {priceFormatting(discountPrice)}
                                                    {/*<span className={"verdana_regular"}>₽</span>*/}
                                                </div>
                                            </div>
                                            :
                                            <div className={classes.just_price}>
                                                {priceFormatting(price)}
                                                {/*<span className={"verdana_regular"}>₽</span>*/}
                                            </div>
                                        : ''
                                }

                                <div className={classes.text_after_price}>
                                    за {props.excursionType.type_name === "INDIVIDUAL" ? "впечатление" : "гостя"}
                                </div>
                            </div>
                        </div>

                        <div className={classes.like_block}>
                            <HbButton
                                color={'black'}
                                className={classes.like_button}
                                onClick={handleLikeClick}
                            >
                                <div
                                    className={classes.like_img_block}
                                >
                                    <HbLikeIcon
                                        likeClick={likeClick}
                                        setLikeClick={setLikeClick}
                                        excursion_id={props.excursion.id}
                                        likeImg={'outline'}
                                    />
                                </div>

                                <span className={classes.like_text}>
                                    {wishText}
                                </span>
                            </HbButton>
                        </div>
                    </div>

                    {/* <div className={classes.yandex_split_widget}> */}
                        {/* <div id="yandex_split_widget"></div> */}
                        {/* <yandex-pay-badge
                            type="bnpl"
                            amount={discountPrice !== null && discountPrice !== 0 && discountPrice !== price ? discountPrice : price}
                            size="m"
                            variant="detailed"
                            color="primary"
                            merchant-id={process.env.REACT_APP_YANDEX_SPLIT_MERCHANT_ID}
                        />
                    </div> */}

                    <div className={classes.type_row}>
                        <TypeSelector
                            excrusionType={props.excursionType}
                            excursion={props.excursion}
                        />
                    </div>

                    <div 
                        className={classes.reviews_city_row}
                        onClick={() => props.setIsReviewsVisible(true)}
                    >
                        {/*<div className={classes.pink_dot}>*/}

                        {/*</div>*/}
                        <img
                            className={classes.rating_star_img}
                            src={RatingStarFilled}
                            alt={'star'}
                        />

                        <div className={classes.reviews}>
                            <div className={classes.review_rating}>
                                {props.excursion.rating.toFixed(1)}
                            </div>

                            <div className={classes.review_count}>
                                {/* ({props.excursion.reviews_count}) */}
                                (<span>отзывов: {props.excursion.reviews_count}</span>)
                            </div>
                        </div>

                        {/* <div className={classes.city_name}>
                            {props.excursion.city_name}
                        </div> */}
                    </div>

                    <div className={classes.duration_row}>
                        <div className={classes.duration_img}>
                            <img
                                src={Clock}
                                alt={'dur'}
                            />
                        </div>

                        <div className={classes.duration}>
                            {props.excursionType.duration}
                        </div>
                    </div>

                    <div 
                        className={classes.button_row}
                        id='place_call_to_action'
                    >
                        <HbButton
                            className={classes.booking_button}
                            // color={'blue'}
                            color={'green'}
                            // color={'pink'}
                            onClick={() => navigate(`/e/b?id=${props.excursion.id}&type=${props.excursionType.type_name}${searchParams.has('promocode') ? `&promocode=${searchParams.get('promocode')}` : ''}`)}
                        >
                            Выбрать даты
                            {/* Забронировать */}
                        </HbButton>

                        {/*<HbButton*/}
                        {/*    className={classes.gift_button}*/}
                        {/*    color={'green'}*/}
                        {/*>*/}
                        {/*    Подарить эту экскурсию*/}
                        {/*</HbButton>*/}
                    </div>

                    <div 
                        className={classes.text_after_button}
                    >
                        <p>Не понравится - <u>вернём деньги!</u> Без чека.</p>
                        <p>Можно оплатить частями с <span className={classes.yandex_split_red}>Яндекс Сплит</span>. <u>Без переплат!</u></p>
                    </div>
                    
                    <div className={classes.yandex_split_widget}>
                        {/* <span className={classes.yandex_split_text}>Яндекс Сплит</span> */}

                        {/* <div id="yandex_split_widget"></div> */}
                        <yandex-pay-badge
                            type="bnpl"
                            amount={discountPrice !== null && discountPrice !== 0 && discountPrice !== price ? discountPrice : price}
                            // size="l"
                            size="m"
                            variant="detailed"
                            color="primary"
                            merchant-id={process.env.REACT_APP_YANDEX_SPLIT_MERCHANT_ID}
                        />

                        {/* <span className={classes.yandex_split_text}>
                            Без переплат!
                        </span> */}
                    </div>

                    {/* <div className={classes.text_after_button}>
                        <p>Можно оплатить частями. <u>Без переплат!</u></p>
                        <p>Не понравится - <u>вернём деньги!</u> Без чека.</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ExcursionBookingPreview;