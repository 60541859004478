import React, { useEffect, useRef, useState } from 'react';
import classes from "./ExcursionDescription.module.scss";
import HbButton from "../../UI/HbButton/HbButton";
import { isFullObj } from "../../../helpers/ObjHelper";
import StartWaypoint from '../../../images/icons/StartWaypoint.svg';
import EndWaypoint from '../../../images/icons/EndWaypoint.svg';
import { useSearchParams } from 'react-router-dom';
import { ImgStretchingFixedDiv } from 'helpers/ImgStretching';
import { baseURL } from 'API/HttpAxios';
import { useObserverBlockEntry } from 'hooks/useObserverBlockEntry';
import { ImgStretching2 } from 'helpers/ImgStretching2';
import RatingStarFilled from '../../../images/icons/RatingStarFilled.svg';

const ExcursionDescription = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [compositionClasses, setCompositionClasses] = useState([classes.composition]);
    const [isCompositionShow, setIsCompositionShow] = useState(false);
    const [compositionButtonBody, setCompositionButtonBody] = useState('Показать всё');

    useEffect(() => {
        if (isCompositionShow) {
            // setCompositionClasses([...compositionClasses, classes.composition_full]);
            setCompositionClasses([classes.composition_full]);
            setCompositionButtonBody('Скрыть');
        } else {
            setCompositionClasses([classes.composition])
            setCompositionButtonBody('Показать всё');
        }
    }, [isCompositionShow])

    // Get photo count for different screen sizes
    var last_index = 2;

    if (window.innerWidth > 950) {
        last_index = 4;
    }

    // Click on review img
    const clickOnReviewImg = (e) => {
        e.stopPropagation();
        setSearchParams({ ...searchParams, 'reviews_img_src': e.currentTarget.getAttribute('data-img-src') });
    }

    // Load review photos by scroll
    const [isGuestPhotoRowVisible, setIsGuestPhotoRowVisible] = useState(false);
    const guestPhotoTitle = useRef(null);
    const isGuestPhotoTitleEnter = useObserverBlockEntry(guestPhotoTitle);

    useEffect(() => {
        if (isGuestPhotoTitleEnter) {
            setIsGuestPhotoRowVisible(true);
        }
    }, [isGuestPhotoTitleEnter])

    // Load organizer avatar
    const organizerAvatar = useRef(null);

    useEffect(() => {
        if (organizerAvatar.current) {
            ImgStretching2(organizerAvatar);
        }
    }, [organizerAvatar])

    return (
        <div
            className={classes.description_container}
            id='excursion_description'
        >
            {/* Excursion's organizer */}
            <div className={classes.organizer_container}>
                <div
                    className={classes.organizer_avatar_container}
                    ref={organizerAvatar}
                    // data-src={'https://i.pinimg.com/564x/cf/13/ea/cf13ea991e6cf4fa233b801cdbaff3cf.jpg'}
                    data-src={baseURL + props.excursion.organizer_info.avatar_path}
                >
                    
                </div>

                <div className={classes.organizer_info_container}>
                    <div className={[classes.organizer_name, 'truncate'].join(' ')}>
                        {/* Jojo Bernstein Bernstein */}
                        {props.excursion.organizer_info.name}
                    </div>

                    <div className={classes.organizer_type}>
                        организатор
                    </div>

                    <div className={classes.organizer_rating_container}>
                        <img
                            className={classes.organizer_rating_star}
                            src={RatingStarFilled}
                            alt={'+'}
                        />

                        <div className={classes.organizer_rating}>
                            {props.excursion.organizer_info.total_rating.toFixed(1)}
                        </div>

                        <div className={classes.organizer_rating_count}>
                            (
                                <span>отзывов: {props.excursion.organizer_info.total_review_count}</span>
                            )
                        </div>
                    </div>
                </div>

                <div className={classes.organizer_description_container}>
                    {props.excursion.organizer_info.description}
                </div>
            </div>

            {/* Excursion's meta */}
            <div className={classes.meta_description}>
                <div className={classes.meta_description_title}>
                    Чем вы займетесь
                    {/* Подробнее о впечатлении: */}
                </div>
                {props.excursion.preview_description}
            </div>

            {/* Excursion's includes */}
            {/*<div className={classes.includes_container}>*/}
            {/*    <div className={classes.includes_title}>*/}
            {/*        Что включено*/}
            {/*    </div>*/}

            {/*    <div className={classes.includes_block}>*/}
            {/*        <div className={classes.includes_row}>*/}
            {/*            <div className={classes.includes_img_block}>*/}
            {/*                <img*/}
            {/*                    className={classes.includes_img}*/}
            {/*                    src={ReturnMoney}*/}
            {/*                    alt={'icon'}*/}
            {/*                />*/}
            {/*            </div>*/}

            {/*            <div className={classes.includes_text}>*/}
            {/*                Если вам не понравится экскурсия - мы вернем вам деньги. Без чека.*/}
            {/*            </div>*/}

            {/*        </div>*/}

            {/*        <div className={classes.includes_row}>*/}
            {/*            <div className={classes.includes_img_block}>*/}
            {/*                <img*/}
            {/*                    className={classes.includes_img}*/}
            {/*                    src={ReturnGuarantee}*/}
            {/*                    alt={'icon'}*/}
            {/*                />*/}
            {/*            </div>*/}

            {/*            <div className={classes.includes_text}>*/}
            {/*                Гарантированный возврат при отмене за 10 часов до начала впечатления*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        {*/}
            {/*            isFullObj(props.excursionType) && props.excursionType.includeds.length > 0 && props.excursionType.includeds.map((included, index) => {*/}
            {/*                return (*/}
            {/*                    <div*/}
            {/*                        className={classes.includes_row}*/}
            {/*                        key={`included_${index}`}*/}
            {/*                    >*/}
            {/*                        <div className={classes.includes_img_block}>*/}
            {/*                            <img*/}
            {/*                                className={classes.includes_img}*/}
            {/*                                src={baseURL + included.img_path}*/}
            {/*                                alt={'icon'}*/}
            {/*                            />*/}
            {/*                        </div>*/}

            {/*                        <div className={classes.includes_text}>*/}
            {/*                            {included.body}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* Excursion's composition */}
            <div className={classes.composition_container}>
                <div className={classes.composition_title}>
                    Более подробно
                    {/* Подробнее о впечатлении: */}
                </div>

                <div className={classes.composition_block}>
                    {/*<div className={`truncate ${compositionClasses.join(' ')}`}>*/}
                    <div className={`${compositionClasses.join(' ')}`}>
                        {props.excursion.main_description}
                    </div>

                    <HbButton
                        className={classes.composition_button}
                        color={'black'}
                        onClick={() => setIsCompositionShow(!isCompositionShow)}
                    >
                        {compositionButtonBody}
                    </HbButton>
                </div>
            </div>

            {/* Excursion's route */}
            <div className={classes.route_container}>
                <div className={classes.route_title}>
                    {
                        isFullObj(props.excursionType) && props.excursionType.waypoints.length > 1
                            ?
                            'Маршрут'
                            :
                            'Продолжительность'
                    }
                </div>

                <div className={classes.route_block}>
                    <div className={classes.route_duration}>
                        {isFullObj(props.excursionType) && props.excursionType.duration}
                    </div>

                    {
                        isFullObj(props.excursionType) && props.excursionType.waypoints.length > 1
                        &&
                        <div className={classes.route_grey_line}>

                        </div>
                    }



                    {
                        isFullObj(props.excursionType) && props.excursionType.waypoints.length > 1
                        &&
                        <div className={classes.route_detailed_container}>
                            <div className={classes.route_extreme_container_start}>
                                <div className={classes.route_extreme_icon}>
                                    <img
                                        src={StartWaypoint}
                                        alt={'start'}
                                    />
                                </div>

                                <div className={classes.route_point_waypoint}>
                                    {
                                        isFullObj(props.excursionType) && props.excursionType.waypoints[0].waypoint
                                        // isFullObj(props.excursionType) && props.excursionType.waypoints
                                        // ? props.excursionType.waypoints[0].waypoint
                                        // : ''
                                    }
                                </div>
                            </div>

                            <div className={classes.route_middle_container}>
                                <div className={classes.route_pink_vertical}>

                                </div>

                                <div className={classes.route_point_container}>
                                    {
                                        isFullObj(props.excursionType) && props.excursionType.waypoints.map((waypoint, index) => {
                                            if (index !== 0 && index !== props.excursionType.waypoints.length - 1) {
                                                return (
                                                    <div
                                                        className={classes.route_point_row}
                                                        key={`waypoint_${index}`}
                                                    >
                                                        <div className={classes.route_point_dot}>

                                                        </div>

                                                        <div className={classes.route_point_waypoint}>
                                                            {waypoint.waypoint}
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        })
                                    }
                                </div>
                            </div>

                            <div className={classes.route_extreme_container_end}>
                                <div className={classes.route_extreme_icon}>
                                    <img
                                        src={EndWaypoint}
                                        alt={'end'}
                                    />
                                </div>

                                <div className={classes.route_point_waypoint}>
                                    {
                                        isFullObj(props.excursionType) && props.excursionType.waypoints[props.excursionType.waypoints.length - 1].waypoint
                                    }
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>

            {/* Important info */}
            <div className={classes.important_info_container}>
                {
                    isFullObj(props.excursionType) && props.excursionType.importants.length > 0
                    &&
                    <div>
                        <div className={classes.important_info_title}>
                            Важная информация
                        </div>

                        <div className={classes.important_info_block}>
                            {
                                props.excursionType.importants.map((important, index) => {
                                    return (
                                        <div
                                            className={classes.important_info_row}
                                            key={`important_${index}`}
                                        >
                                            <div className={classes.important_info_dot}>

                                            </div>

                                            <div className={classes.important_info}>
                                                {important.body}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>

            {/* Guest photo */}
            {
                props.excursion.review_imgs !== undefined && props.excursion.review_imgs.length > 0
                    ?
                    <div className={classes.guest_photo_container}>
                        <div
                            className={classes.guest_photo_title}
                            ref={guestPhotoTitle}
                        >
                            Фото от гостей ({props.excursion.review_imgs.length})
                        </div>

                        <div className={classes.review_photos_block}>
                            {
                                props.excursion.review_imgs.map((photo, index) => {
                                    if (index > last_index) return null;

                                    return (
                                        <div
                                            key={`review_photo_${index}`}
                                            className={classes.review_photo}
                                            data-img-src={photo.img_src.match(/\/([\w\d]+)\.webp$/)[1]}
                                            onClick={clickOnReviewImg}
                                        >
                                            {
                                                isGuestPhotoRowVisible
                                                    ?
                                                    <img
                                                        src={baseURL + photo.preview_img_src}
                                                        alt={'review_photo'}
                                                        onLoad={ImgStretchingFixedDiv}
                                                        data-block={'review_photo'}
                                                    />
                                                    : null
                                            }


                                            {
                                                index === last_index && props.excursion.review_imgs.length > last_index + 1
                                                    ?
                                                    (
                                                        <div
                                                            className={classes.review_photos_count}
                                                        >
                                                            +{props.excursion.review_imgs.length - last_index - 1}
                                                        </div>
                                                    )
                                                    : ''
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    : null
            }

        </div>
    );
};

export default ExcursionDescription;